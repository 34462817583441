<template>
  <div class="fuiler" id="target">
    <div class="nav_block">
      <Header class="Header"></Header>
    </div>
    <div class="main">
      <div class="black">
        <div class="router">首页>>当季主题>>{{ Title }}</div>
        <div class="block" @click="black">返回</div>
      </div>

      <div class="search" @click="openSearch"><i></i></div>
      <div class="data" v-for="(item, index) in data" :key="index">
        <router-link
          v-if="item._id"
          :to="{
            path: '/mobile/browseText/' + item._id,
            query: { qs: 1 },
          }"
        >
          <div class="block">
            <div class="title">{{ item.Title }}</div>
            <div class="Articles_id">
              {{ item.Articles_id }}
            </div>
            <div class="info">
              <div class="ico"></div>
              <div class="name">
                <div
                  v-for="(items, index) in item.Team"
                  :key="index"
                  class="nameData"
                >
                  {{ items.Name }}
                </div>
              </div>
              <div class="interactive">
                <div class="comment"><i></i>{{ item.Comment }}</div>
                <div class="like"><i></i>{{ item.Give }}</div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <van-pagination
        class="page"
        v-model="query.Page"
        :total-items="query.Total"
        :items-per-page="10"
        @change="todata"
        mode="simple"
      />
    </div>

    <Footer class="Footer"></Footer>
    <div class="loading" v-if="loading">
      <van-loading />
    </div>
    <Search ref="Search"></Search>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";
import { findlist } from "../../../api/browseArticles";
import { Pagination, Loading, Toast } from "vant";
import Search from "../conponents/Search";

export default {
  data() {
    return {
      loading: false,
      query: {
        Page: 1,
        Total: 1,
      },
      Title: "",
      data: [],
      initData: [],
    };
  },
  components: {
    Search,
    Header,
    Footer,
    [Pagination.name]: Pagination,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
  },
  mounted() {
    Toast("当前为随机排列文章进行加载");
    this.todata();
    document.getElementById("target").scrollIntoView();
  },
  methods: {
    openSearch() {
      this.$refs.Search.open();
      document.body.style.overflow = "hidden";
    },
    todata() {
      let query = this.$route.query;
      let Title = query.Title;
      this.Title = Title;
      this.loading = true;
      document.body.style.overflow = "hidden";
      let Page = this.query.Page;

      findlist({ Page, Title }).then((res) => {
        let data = res.sum;
        this.data = data;
        this.query.Total = res.Total;
        this.loading = false;
        document.body.style.overflow = "auto";
        document.getElementById("target").scrollIntoView();
      });
    },
    black() {
      let query = this.$route.query;
      let Title = query.Title;
      let TitleNumber = query.TitleNumber;
      let Grounding = query.Grounding;
      if (Grounding) {
        window.history.go(-1);
      } else {
        this.$router.push({
          path: "/mobile/details",
          query: { Title, TitleNumber },
        });
      }

      // /mobile/details?TitleNumber=第二季&Title=校园档案
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .nav_block {
    position: relative;
    background-image: url("../../../assets/Image/public/bg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 11rem;

    .Header {
      background-image: url("../../../assets/Image/public/header.png");
      background-size: 100% 100%;
      height: 3rem;
    }

    @mixin nav_block {
      position: absolute;
      width: 18rem;
      height: 6rem;
      padding: 0.8rem;
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 0 15px 1px #78432e;
      font-size: 0.8rem;
      line-height: 1rem;
      top: 6rem;
      text-indent: 1rem;
    }

    .block {
      left: 50%;
      margin-left: -10rem;
      @include nav_block;
    }

    .NavTitle {
      background-image: url("../../../assets/Image/public/NavTitle.png");
      background-size: 100% 100%;
      width: 7rem;
      height: 2rem;
      display: inline-block;
    }
  }

  .main {
    width: 100%;
    .black {
      width: 100%;
      height: 2rem;
      margin-top: 1rem;
      margin-bottom: 0.3rem;
      position: relative;
      .browse {
        position: absolute;
        left: 1.9rem;
        font-size: 0.8rem;
        top: 0.5rem;

        a {
          color: #78432e;
          text-decoration: underline;
        }
      }
      .router {
        position: absolute;
        left: 0;
        top: 0.5rem;
        font-size: 0.5rem;
        color: #666;
        width: 100%;
        text-indent: 1rem;
        margin-bottom: 1.2rem;
      }
      .block {
        position: absolute;
        right: 1.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }
    }
    .search {
      border: 1px solid #778398;
      width: 22rem;
      height: 1.8rem;
      margin: 0 auto;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      font-size: 0.8rem;
      line-height: 1.8rem;
      border-radius: 0.6rem;
      position: relative;
      i {
        position: absolute;
        background-size: 100% 100%;
        background-image: url("../../../assets/Image/mobile/browseArticles/Search.png");
        width: 0.8rem;
        height: 0.8rem;
        display: inline-block;
        left: 50%;
        top: 50%;
        margin-top: -0.4rem;
        margin-left: -0.4rem;
      }
    }

    .data {
      width: 22rem;
      margin: 0 auto;
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        color: #000;
      }
      .block {
        width: 100%;
        height: 3rem;
        border-bottom: #93523a 1px solid;
        position: relative;
        .title {
          font-size: 0.8rem;
          margin-bottom: 0.4rem;
        }
        .Articles_id {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 0.75rem;
          color: #666666;
        }
        .nameData {
          display: inline-block;

          margin-right: 0.6rem;
        }
        .info {
          .ico {
            background-color: #93523a;
            border-radius: 10rem;
            width: 0.5rem;
            height: 0.5rem;
            display: inline-block;
          }
          .name {
            display: inline-block;
            margin-left: 0.5rem;
            color: #666666;
            font-size: 0.5rem;
          }
          .interactive {
            position: absolute;
            right: 0;
            bottom: 0;
            @mixin ico {
              width: 0.9rem;
              height: 0.9rem;
              display: inline-block;
              background-size: 100% 100%;
              margin-right: 0.4rem;
            }
            .comment {
              display: inline-block;
              i {
                @include ico;
                background-image: url("../../../assets/Image/public/comment.png");
              }
            }
            .like {
              margin-left: 0.4rem;
              display: inline-block;
              i {
                @include ico;
                background-image: url("../../../assets/Image/public/like.png");
              }
            }
          }
        }
      }
    }
    .page {
      margin-bottom: 2rem;
      ::v-deep .van-pagination__item {
        color: #93523a;
      }

      ::v-deep .van-pagination__item:active {
        background-color: #936c5b;
        color: #ffffff;
      }
    }
  }
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .van-loading {
      color: #936c5b;
    }
  }
  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
  }
  .red {
    color: #ff725e;
  }
}
</style>
