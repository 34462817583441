<template>
  <div class="Search" v-if="Search">
    <div class="form">
      <div class="input">
        <i></i> <input v-model="input" placeholder="请输入文章标题" />
        <div class="Cancel" @click="empty"></div>
      </div>
      <span @click="close">取消</span>
    </div>
    <div class="Nodata" v-if="input && data.length == 0">暂无数据</div>
    <div class="Fast" v-if="input">
      <div class="data" v-for="(item, index) in data" :key="index">
        <router-link
          :to="{
            path: '/mobile/browseText/' + item._id,
            query: { qs: 1 },
          }"
          @click="preserData(item.Title, item._id, item.Titles)"
          v-html="item.Title"
        ></router-link>
      </div>
    </div>
    <div class="title" v-if="!input">热点</div>
    <div class="Hot" v-if="!input">
      <div class="data" v-for="(item, index) in Hotdata" :key="index">
        <router-link
          :to="{
            path: '/mobile/browseText/' + item._id,
          }"
          >{{ index + 1 }}:{{ item.Title }}</router-link
        >
      </div>
    </div>
    <div class="title" v-if="!input">
      搜索历史 <span @click="rmLog">清除历史</span>
    </div>
    <div class="History" v-if="!input">
      <div class="data" v-for="(item, index) in SearchLog" :key="index">
        <router-link
          :to="{
            path: '/mobile/browseText/' + item._id,
            query: { qs: 1 },
          }"
          >{{ item.Titles }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { findRegex, findHot } from "../../../api/browseArticles";
import { Dialog, Notify } from "vant";

export default {
  watch: {
    input: function () {
      this.fachfind();
      // console.log(val);
    },
  },
  mounted() {
    this.fachhot();
    this.obtainLog();
  },
  data() {
    return {
      Search: false,
      input: "",
      data: [],
      SearchLog: [],
      Hotdata: [],
    };
  },
  components: {
    [Dialog.name]: Dialog,
    [Notify.Component.name]: Notify.Component,
  },
  methods: {
    //清除历史
    rmLog() {
      Dialog.confirm({
        title: "系统",
        message: "是否要清除当前历史记录？",
      }).then(() => {
        this.$store.state.SearchLog = [];
        this.SearchLog = [];
        Notify({ type: "primary", message: "清除成功" });
      });
    },
    fachhot() {
      findHot().then((res) => {
        let sum = res.sum;
        for (let item in sum) {
          let Title = sum[item].Title;
          if (Title.length >= 10) {
            Title = Title.substring(0, 10) + "…";
            sum[item].Title = Title;
          }
        }

        this.Hotdata = sum;
      });
    },
    obtainLog() {
      let SearchLog = this.$store.state.SearchLog;
      this.SearchLog = SearchLog;
    },
    preserData(Title, _id, Titles) {
      let addData = { Title, _id, Titles };
      let SearchLog = this.$store.state.SearchLog;
      SearchLog.push(addData);
      this.$store.state.SearchLog = SearchLog;
    },
    fachfind() {
      let input = this.input;
      if (input) {
        findRegex({ input }).then((res) => {
          let sum = res.sum;
          for (let item in sum) {
            let Title = sum[item].Title;
            let Titles = Title;
            Title = Title.replace(
              input,
              `<span style="color:#ff725e ">${input}</span>`
            );
            sum[item].Title = Title;
            sum[item].Titles = Titles;
          }
          this.data = sum;
        });
      }
    },
    open() {
      this.Search = true;
    },
    close() {
      this.Search = false;
      document.body.style.overflow = "auto";
    },
    empty() {
      this.input = "";
    },
  },
};
</script>

<style scoped lang="scss">
.Search {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 20;
  .form {
    width: 20rem;
    height: 2.5rem;
    margin: 0 auto;
    margin-top: 2rem;
    position: relative;
    .input {
      width: 17rem;
      height: 2.5rem;
      border-radius: 1.4rem;
      background-color: #f1f1f1;
      i {
        position: absolute;
        background-size: 100% 100%;
        background-image: url("../../../assets/Image/mobile/browseArticles/Search.png");
        width: 0.8rem;
        height: 0.8rem;
        display: inline-block;
        left: 1.4rem;
        top: 50%;
        margin-top: -0.4rem;
        margin-left: -0.4rem;
      }
      input {
        border: none;
        width: 15rem;
        height: 100%;
        font-size: 0.7rem;
        text-indent: 2.4rem;
        background: none;
      }
      .Cancel {
        background-size: 100% 100%;
        background-image: url("../../../assets/Image/mobile/browseArticles/Cancel.png");
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        right: 3.8rem;
        top: 0.8rem;
      }
    }

    span {
      height: 2.5rem;
      line-height: 2.5rem;
      color: #606060;
      font-size: 0.7rem;
      position: absolute;
      right: 0.4rem;
      top: 0;
    }
  }
  .Nodata {
    width: 100%;
    font-size: 0.7rem;
    text-align: center;
    height: 3rem;
    line-height: 3rem;
    color: #aaaaaa;
    border-bottom: 1px solid #e8e8e8;
  }
  .Fast {
    width: 100%;
    height: 20rem;
    margin-top: 1rem;
    .data {
      width: 100%;
      height: 2rem;
      line-height: 2rem;
      border-bottom: 1px solid #e8e8e8;
      font-size: 0.7rem;
      text-indent: 0.8rem;
    }
    a {
      width: 100%;
      height: 100%;
      color: #000000;
      display: block;
    }
  }
  .title {
    font-size: 0.9rem;
    margin: 0 auto;
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
    width: 20rem;
    span {
      color: #666666;
      font-size: 0.7rem;
      margin-left: 1rem;
    }
  }
  .Hot {
    width: 20rem;
    height: 10rem;
    margin: 0 auto;
    margin-top: 1.5rem;
    .data {
      width: 10rem;
      height: 2rem;
      line-height: 2rem;
      display: inline-block;
      font-size: 0.7rem;
      margin-bottom: 0.3rem;
      a {
        width: 100%;
        height: 100%;
        color: #000000;
        display: block;
      }
    }
  }
  .History {
    width: 20rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .data {
      min-width: 2rem;
      height: 1rem;
      font-size: 0.7rem;
      padding: 0.5rem 1.3rem;
      border-radius: 0.4rem;
      background-color: #f3f3f3;
      margin-bottom: 0.5rem;
      margin-right: 0.6rem;
      text-align: center;
      a {
        width: 100%;
        height: 100%;
        color: #000000;
        display: block;
      }
    }
  }
}
</style>
